<template>
  <div>
    <b-alert v-model="isConfirm" variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Are you sure Do you want to initiate refund?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="cancelRefund" variant="danger" class="mr-2"
              >Cancel</b-button
            >
            <b-button @click="initiateRefund" variant="success">Yes</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Transaction ID">
            <b-form-input
              v-model="txnId"
              id="txnId"
              placeholder="Transaction ID"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Cancellation Percentage">
            <b-form-select
              v-model="refundPercentage"
              placeholder="Description"
              :options="options"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Remarks">
            <b-form-select
              v-model="selectedTemplate"
              :options="templateOptions"
            ></b-form-select>
            <b-form-textarea
              v-if="selectedTemplate === 'Custom'"
              v-model="refundRemark"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <div class="d-flex">
      <div>
        <b-button
          :disabled="isConfirm"
          size="lg"
          @click="confirmRefund"
          block
          variant="primary"
        >
          Refund
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BImg,
  BAlert,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BImg,
    BAlert,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      isConfirm: false,
      txnId: this.$route.query._id,
      refundPercentage: 90,
      options: [
        { value: 90, text: "10%" },
        { value: 75, text: "25%" },
        { value: 100, text: "0%" },
      ],
      selectedTemplate: "Template 1",
      refundRemark: "",
      templateOptions: [
        { value: "Template 1", text: "This is Template 1" },
        { value: "Template 2", text: "This is Template 2" },
        { value: "Template 3", text: "This is Template 3" },
        { value: "Custom", text: "Add Custom Remark" },
      ],
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    validateObjectId() {
      return /^[0-9a-fA-F]{24}$/.test(this.txnId);
    },
    cancelRefund() {
      this.isConfirm = false;
    },
    confirmRefund() {
      if (!this.validateObjectId()) {
        return this.makeToast("danger", "Error", "Invalid Txn Id");
      }

      if (!this.isConfirm) {
        this.isConfirm = true;
      }
    },
    initiateRefund() {
      let payload = {
        checkoutID: this.txnId,
        refundPercentage: this.refundPercentage,
        refundRemark:
          this.selectedTemplate === "Custom"
            ? this.refundRemark
            : this.selectedTemplate,
      };
      if (!this.validateObjectId()) {
        return this.makeToast("danger", "Error", "Invalid Txn Id");
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/checkout/refund-amount`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.isConfirm = false;
            this.makeToast("success", "Success", response.data.message);
          }
        })
        .catch((error) => {
          this.makeToast("danger", "Error", error.response.data.message);
          console.log(error.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
